$(function () {
  'use strict';

  const $tableWrapper = $('.js-prices-table');
  const $table = $('table', $tableWrapper);

  let firstColumnWidth;
  let $fixedTable;

  /**
   * Перенаправление пользователя на страницу типа доставки.
   *
   * @param $row
   */
  function redirect($row) {
    if ($row) {
      const url = $row.data('url');

      if (url) {
        window.location = `/${url}`;
      }
    }
  }

  function initTableScroll() {
    firstColumnWidth = $('thead td', $table).first().outerWidth();
    $fixedTable = $(`<table class="prices-table-fixed" style="width:${firstColumnWidth}px !important;"><thead></thead><tbody></tbody></table>`);

    // клонируем первую колонку таблицы для "фиксации".
    $('tr', $table)
      .each((i, el) => {
        let $td = $('td', el).first();
        let cellHeight = $td.outerHeight();

        const $tr = $(`<tr><td style="height:${cellHeight}px !important;">${$td[0].innerText}</td></tr>`);

        $tr.data($(el).data());

        $(i === 0 ? 'thead' : 'tbody', $fixedTable).append($tr);
      });

    // добавляем таблицу с одной колонкой заголовков строк.
    $tableWrapper.append($fixedTable);
    $table.addClass('scroll');

    $.each([$fixedTable, $table], (i, tableItem) => {
      // обработка различных событий сразу для двух таблиц

      $('tr', tableItem)
        .on('mouseover', (e) => {
          // подсветка строк таблиц при наведении.

          let $target = $(e.currentTarget),
            targetIndex = $target.index();

          if ($target.parents('thead').length === 0) {
            $.each([$fixedTable, $table], (j, item) => {
              $('tbody tr', item).eq(targetIndex).addClass('hover');
            });
          }
        })
        .on('mouseleave', () => {
          // скрытие подсветки строк таблиц при наведении.

          $.each([$fixedTable, $table], (j, item) => {
            $('.hover', item).removeClass('hover');
          });
        });
    });

    ///////////////////////
    // отделение клика по строке таблицы, от свайпа/выделения содержимого.

    let touchStart = false;
    let touchStartPosition;
    let lastTouchPosition;

    $table
      .on('mousedown touchstart', (e) => {
        touchStart = true;
        lastTouchPosition = touchStartPosition = e.touches ? e.touches[0].pageX : e.pageX;
      })
      .on('mousemove touchmove', (e) => {
        // свайп непосредственно таблицы

        if (touchStart) {
          lastTouchPosition = e.touches ? e.touches[0].pageX : e.pageX;
        }
      })
      .on('mouseup touchend', (e) => {
        touchStart = false;

        if (Math.abs(touchStartPosition - lastTouchPosition) <= 10) {
          redirect($(e.target).closest('tr'));
        }
      });

    ///////////////////////

    $tableWrapper.trigger('fix-table-inited');

    let resizeTimeout = 0;

    $(window)
      .on('resize', () => {

        clearTimeout(resizeTimeout);

        resizeTimeout = setTimeout(() => {
          // пересчет высоты ячеек в клонированной колонке
          $('tr', $table)
            .each((i, el) => {
              const cellHeight = $('td', el).first().outerHeight();

              $(i === 0 ? 'thead' : 'tbody', $fixedTable).find('tr').eq(i === 0 ? i : i - 1).find('td').first().attr('style', `height: ${cellHeight}px !important`);
            });
        }, 300);
      });
  }

  if ($tableWrapper.length) {
    // небольшая задержка для подгрузки шрифтов и т.п.

    setTimeout(initTableScroll, 100);
  }
});

/**
 * Created by Ilya Kvant [fatalerror2069@gmail.com] on 19.07.2018
 *
 * Работа с инфоблоком о использовании cookies.
 */
$(function() {
  'use strict';

  let $infoblock = $('.js-infoblock'),
      cookieName = 'info-approved';

  if ($.cookie(cookieName) === undefined) {
    $infoblock.show();

    $('.js-info-approved', $infoblock).on('click', e => {
      $infoblock.hide();

      $.cookie(cookieName, true, {expires: 365, path: '/'});

      return false;
    });
  }
});
/**
 * Created by Ilya Kvant [fatalerror2069@gmail.com] on 17.08.2018
 */
$(function () {

  const $cart = $('.js-cart');
  const lang  = $('html').attr('lang');

  if ($cart.length) {

    function numberof(numberof, suffix) {
      // array('ученик', 'ученика', 'учеников')
      numberof = Math.abs(numberof);

      const keys       = [2, 0, 1, 1, 1, 2];
      const mod        = numberof % 100;
      const suffix_key = mod > 4 && mod < 20 ? 2 : keys[Math.min(mod % 10, 5)];

      return suffix[suffix_key];
    }

    function getItemSum($item) {
      return +((+$('.js-item-amount', $item).val() * $item.data('price')).toFixed(2));
    }

    /**
     * Обновляем сумму по конкретному товару.
     *
     * @param $item
     */
    function updateSum($item) {
      $('.js-item-sum', $item).text(`$${getItemSum($item)}`);

      updateTotalSum();
    }

    /**
     * Обновление общего кол-ва и суммы товаров в корзине.
     */
    function updateTotalSum() {
      let total       = 0;
      let itemsAmount = 0;

      $('.js-cart-item', $cart).each((i, item) => {
        const $item = $(item);

        total += getItemSum($item);

        itemsAmount += +$('.js-item-amount', $item).val();
      });

      total = +total.toFixed(2);

      $('.js-total-info b', $cart).text(`$${total}`);
      $('.js-total-info span', $cart).text(`${itemsAmount} ${numberof(itemsAmount, lang === 'ru' ? ['товар', 'товара', 'товаров'] : ['item', 'items', 'items'])}`);
    }

    let loadInProcess = false;

    $('.js-cart-remove-item', $cart)
      .on('click', (e) => {
        // удаление товара из карзины.

        if (loadInProcess) {
          return false;
        }
        loadInProcess = true;

        const $item = $(e.target).parents('.js-cart-item');

        $item.remove();

        $.ajax({
          url:      '/cart/ajax-remove',
          dataType: 'json',
          type:     'post',
          cache:    false,
          data:     {
            id: +$item.data('id')
          },
          success:  function (res) {
            loadInProcess = false;

            if (res.success) {
              // обновляем кол-во товаров на иконке карзины.

              if (res.data === 0) {
                $('.js-cart-items-count').hide();
              } else {
                $('.js-cart-items-count')
                  .text(res.data)
                  .show();
              }

              updateTotalSum();
            }
          }
        });

        return false;
      });

    $('.js-cart-inc', $cart)
      .on('click', (e) => {
        // увеличение кол-ва позиций товара.

        const $t     = $(e.target);
        const $input = $t.parent().find('input');

        $input.val(+$input.val() + 1);

        updateSum($t.parents('.js-cart-item'));

        return false;
      });

    $('.js-cart-dec', $cart)
      .on('click', (e) => {
        // уменьшение кол-ва позиций товара.

        const $t     = $(e.target);
        const $input = $t.parent().find('input');

        $input.val(Math.max(+$input.val() - 1, 1));

        updateSum($t.parents('.js-cart-item'));

        return false;
      });

    $('.js-order-transfer')
      .on('click', () => {
        // отправка данных о заказе в личный кабинет.

        $('form', $cart).trigger('submit');

        return false;
      });
  }
});

/**
 * Created by Ilya Kvant [fatalerror2069@gmail.com] on 06.08.2018
 *
 * Скрытие/показ мобильного меню.
 */
$(function () {
  'use strict';

  const $mobileMenu = $('.js-mobile-menu');

  $('.js-menu-trigger')
    .on('click', () => {
      $mobileMenu.toggle();

      return false;
    });
});

/**
 * Created by Ilya Kvant [fatalerror2069@gmail.com] on 25.07.2018
 *
 * Работа с вопросами на стр. FAQ
 */
$(function() {
  'use strict';

  // скрытие/показ блока с вопросом
  $('.js-faq-expand')
    .on('click', e => {
      $(e.currentTarget).parent().toggleClass('active');

      return false;
    });
});
/**
 * Created by Ilya Kvant [fatalerror2069@gmail.com] on 13.08.2018
 *
 * Подгрузка товаров магазина через ajax.
 */
$(function () {
  const $filters = $('.js-catalog-filters').first();

  if ($filters.length) {
    let page = null;
    let loadInProcess = false;

    const $list = $('.js-item-list');
    const query = $filters.serialize();

    $('.js-load-more')
      .on('click', (e) => {
        if (loadInProcess) {
          return false;
        }
        loadInProcess = true;

        const $t = $(e.target);
        const link = $t.data('link');
        const lastPage = +$t.data('max');

        if (page === null) {
          page = +$t.data('page');
        }

        $.ajax({
          url: `${link}&${query}`,
          dataType: 'html',
          type: 'get',
          cache: false,
          data: {
            page
          },
          success: function (res) {
            $list.append(res);

            loadInProcess = false;
          }
        });

        page += 1;

        if (page >= lastPage) {
          $t.hide();
        }

        return false;
      });
  }
});

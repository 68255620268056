$(function () {

  let $body       = $('body'),
      lightOpened = false,
      minWidth    = parseInt($('.body-wrapper').css('min-width'));

  minWidth = isNaN(minWidth) ? 980 : minWidth;

  function __checkWidth() {
    if ($(window).width() < minWidth) {
      $('.lightbox-wrapper').css({position: 'absolute'});
      $body.css({overflow: 'auto'});
    } else {
      $('.lightbox-wrapper').css({position: 'fixed'});
      $body.css({overflow: 'hidden'});
    }
  }

  function __openLightbox(light) {
    lightOpened = true;

    let $light = $('.lightbox-' + light);

    $body.css({overflow: 'hidden'});

    $('.lightbox-wrapper, .lightbox-' + light).show();
    $('.lightbox-wrapper').addClass(light + '-overlay');

    $light.css({marginTop: 100});

    let wh = $(window).height(),
        h  = $light.height();

    if (h < wh) {
      $light.css({marginTop: parseInt((wh - h) / 2)});
    }

    __checkWidth();
  }

  function __closeLightboxes() {
    $('.lightbox .has-error').removeClass('has-error');
    $('.lightbox input[type="text"], .lightbox textarea').val('').text('');
    $('.lightbox input[type="checkbox"]').prop('checked', false);
    $('.lightbox-content').show();
    $('.css-ok, .lightbox, .lightbox-wrapper').hide();

    $body.css({overflow: 'auto'});

    lightOpened = false;

    return false;
  }

  $(window)
    .resize(function () {
      if (lightOpened !== false) {
        __checkWidth();

        let wh = $(window).height();
        $('.lightbox').each(function () {
          let $light = $(this),
              h      = $light.height();

          if (h < wh) {
            $light.css({marginTop: parseInt((wh - h) / 2), marginBottom: 0});
          } else {
            $light.css({marginTop: 100, marginBottom: 100});
          }
        });
      }
    });

  $('.js-open-light')
    .on('click', function () {
      __openLightbox($(this).data('light'));

      return false;
    });

  $('.lightbox__close, .lightbox .js-cancel').on('click', __closeLightboxes);

  $('.lightbox-wrapper')
    .on('click', (e) => {
      if (e.target.className.indexOf('lightbox-wrapper') !== -1) {
        $('.lightbox__close').trigger('click');

        return false;
      }
    })
    .on('beforeSubmit', 'form', function () {
      return false;
    })
    .on('ajaxComplete', 'form', function (event, jqXHR, textStatus) {
      if (+jqXHR.status === 200 && jqXHR.responseJSON.length === 0) {
        // вся валидация прошла успешно и форма сохранена

        let $form    = $(this),
            $wrapper = $form.parents('.lightbox');

        $('[type="text"], textarea', $wrapper).val('');

        $('.lightbox-content', $wrapper).hide();
        $('.lightbox-content.css-ok', $wrapper).show();

        $('.css-fill, .has-success, .has-error', $wrapper).removeClass('css-fill has-success has-error');

        $(window).trigger('resize');
      }

      return false;
    });
});

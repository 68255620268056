/**
 * Created by Ilya Kvant [fatalerror2069@gmail.com] on 25.07.2018
 */
$(function () {
  'use strict';

  let $payments = $('.payments'),
    $paymentsTabs = $('.payments__tabs', $payments),
    $paymentsContent = $('.payments__content', $payments);

  $('a', $paymentsTabs).on('click', e => {
    let $t = $(e.currentTarget);

    if ($t.hasClass('active')) {
      return false;
    }

    $('.active', $paymentsTabs).removeClass('active');
    $t.addClass('active');

    $('> *', $paymentsContent).hide();
    $('> *', $paymentsContent).eq($t.index()).show();

    return false;
  });
});

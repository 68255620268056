/**
 * Created by Ilya Kvant [fatalerror2069@gmail.com] on 26.07.2018
 *
 * Скрытие/показ подменю категорий на мобилках на страницах типа FAQ...
 */
$(function() {

  let $dropdownMenu = $('.js-dropdown-menu');

  if ($dropdownMenu.length) {
    let ww = $(window).width();

    $dropdownMenu
      .on('click', '.css-mobile-dropdown-menu__title', e => {
        if (ww > 768) {
          return false;
        }

        let $p        = $(e.currentTarget).parent(),
            openClass = 'opened';

        // if ($p.hasClass('dropdown-menu__row_link')) {
        //   return true;
        // }

        $p.toggleClass(openClass);
        $('.css-mobile-dropdown-menu__content', $p).slideToggle();

        return false;
      })
      .on('click', '.css-mobile-dropdown-menu__tabs a', e => {
        let $t       = $(e.currentTarget),
            $tabsRow = $t.parent(),
            $root    = $tabsRow.parents('.js-dropdown-menu');

        $('.active', $tabsRow).removeClass('active');
        $t.addClass('active');

        $('.css-mobile-dropdown-menu__content', $root).hide();
        $('.css-mobile-dropdown-menu__content', $root).eq($t.index()).show();

        return false;
      });

    $(window)
      .on('resize', () => {
        ww = $(window).width();

        if (ww > 768) {
          $('.js-dropdown-menu').removeClass('opened');
          $('.css-mobile-dropdown-menu__tabs .active').removeClass('active');

          $('.css-mobile-dropdown-menu__content').each((i, el) => {
            el.removeAttribute('style');
          });
        }
      });
  }
});
/**
 * Created by Ilya Kvant [fatalerror2069@gmail.com] on 10.09.2018
 *
 * Скрипт слайдера баннеров на главной.
 */
$(function () {

  const $banners = $('.js-wide-banners');

  if ($banners.length) {
    const $swiper = new Swiper($banners, {
      autoHeight:     false,
      slidesPerView:  1,
      spaceBetween:   null,
      centeredSlides: true,
      pagination:     {
        el:        '.swiper-pagination',
        clickable: true,
      },
      navigation:     {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }
});

/**
 * Created by Ilya Kvant [fatalerror2069@gmail.com] on 23.07.2018
 *
 * Шаринг страниц в соц. сетях.
 */
$(function () {

  const protocol = location.protocol === 'https:' ? 'https:' : 'http:';
  const isHttps = protocol === 'https:';

  // const services = {
  //   facebook: {
  //     popupUrl: 'https://www.facebook.com/sharer/sharer.php?u={url}', popupWidth: 600, popupHeight: 500
  //   },
  //   twitter: {
  //     popupUrl: 'https://twitter.com/intent/tweet?url={url}&text={title}', popupWidth: 600, popupHeight: 450,
  //   },
  //   mailru: {
  //     popupUrl: protocol + '//connect.mail.ru/share?share_url={url}&title={title}', popupWidth: 550, popupHeight: 360
  //   },
  //   vkontakte: {
  //     popupUrl: protocol + '//vk.com/share.php?url={url}&title={title}', popupWidth: 550, popupHeight: 330
  //   },
  //   odnoklassniki: {
  //     popupUrl: 'http://connect.ok.ru/dk?st.cmd=WidgetSharePreview&service=odnoklassniki&st.shareUrl={url}', popupWidth: 550, popupHeight: 360
  //   },
  //   plusone: {
  //     popupUrl: 'https://plus.google.com/share?url={url}', popupWidth: 700, popupHeight: 500
  //   },
  //   pinterest: {
  //     popupUrl: protocol + '//pinterest.com/pin/create/button/?url={url}&description={title}', popupWidth: 630, popupHeight: 270
  //   }
  // };

  //////////////////////////////////////////////////////////////////////////////

  const Share = {
    vk(purl, ptitle, text, pimg) {
      let url = `https://vk.com/share.php?url=${encodeURIComponent(purl)}&title=${encodeURIComponent(ptitle)}&description=${encodeURIComponent(text)}`;

      // url += '&image=' + encodeURIComponent(pimg);
      // url += '&noparse=true';

      this.popup(url);
    },
    fb(purl, ptitle, text, pimg) {
      let url = `https://www.facebook.com/sharer.php?s=100&p[title]=${encodeURIComponent(ptitle)}&p[summary]=${encodeURIComponent(text)}&p[url]=${encodeURIComponent(purl)}`;

      // url += '&p[images][0]=' + encodeURIComponent(pimg);

      this.popup(url);
    },
    tw(purl, ptitle, text, pimg) {
      let url = `https://twitter.com/share?text=${encodeURIComponent(ptitle)}&url=${encodeURIComponent(purl)}&counturl=${encodeURIComponent(purl)}`;

      // url += '&via=diletant_media';

      this.popup(url);
    },
    ok(purl, ptitle, text, pimg) {
      let url = `https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&service=odnoklassniki&st.shareUrl=${purl}`;

      this.popup(url);
    },
    popup(url) {
      window.open(url, '', 'toolbar=0,status=0,width=626,height=436');
    }
  };

  $('.js-share')
    .on('click', (e) => {
      const $t = $(e.currentTarget);
      const social = $t.data('social');

      if (Share[social]) {
        Share[social](window.location.href, $('title').text(), $('meta[name="description"]').attr('content'));
      }

      return false;
    });
});

/**
 * Created by Ilya Kvant [fatalerror2069@gmail.com] on 14.08.2018
 *
 * Подгрузка записей блога через ajax.
 */
$(function () {
  let page = null;
  let loadInProcess = false;

  const $list = $('.js-blog-item-list');

  $('.js-load-more-blog')
    .on('click', (e) => {
      if (loadInProcess) {
        return false;
      }
      loadInProcess = true;

      const $t = $(e.target);
      const link = $t.data('link');
      const lastPage = +$t.data('max');
      const cat = +$t.data('cat');

      if (page === null) {
        page = +$t.data('page');
      }

      $.ajax({
        url: `${link}`,
        dataType: 'html',
        type: 'get',
        cache: false,
        data: {
          page,
          cat
        },
        success: function (res) {
          $list.append(res);

          loadInProcess = false;
        }
      });

      page += 1;

      if (page >= lastPage) {
        $t.hide();
      }

      return false;
    });
});

/**
 * Created by Ilya Kvant [fatalerror2069@gmail.com] on 25.07.2018
 *
 * Работа калькулятора доставки.
 */
$(function () {
  'use strict';

  const $calc = $('.calc');
  const $calcForm = $('form', $calc);
  const $calcFormWrapper = $('.calc-form', $calc);

  if ($calc.length) {

    let cities = [];
    let ajaxLoad = false;

    const $countrySelect = $('.js-country-select');
    const $regionSelect = $('.js-city-select');

    $.each([$countrySelect, $regionSelect], (i, el) => {
      el
        .select2({
          placeholder: el.data('placeholer'),
          allowClear: false
        });
    });

    ajaxLoad = true;

    // получение всех стран для калькулятора.
    $.ajax({
      url: '/cities/ajax-countries',
      dataType: 'json',
      type: 'get',
      cache: false,
      data: {},
      success: function (res) {
        let html = '<option value=""></option>';

        if (res.success && res.data) {
          res.data.forEach((item, i) => {
            html += `<option value="${item.country}">${item.country}</option>`;
          });
        }

        $countrySelect.html(html);

        ajaxLoad = false;
      }
    });

    $countrySelect
      .on('change', () => {

        if (ajaxLoad) {
          return false;
        }

        const country = $countrySelect.val();

        if (cities[country]) {
          let html = '<option value=""></option>';

          cities[country].forEach((item, i) => {
            html += `<option value="${item.city_id}">${item.region}, ${item.city}</option>`;
          });

          $regionSelect.html(html);
        } else {
          ajaxLoad = true;

          // получение городов для калькулятора, при изменении страны.
          $.ajax({
            url: '/cities/ajax-cities',
            dataType: 'json',
            type: 'get',
            cache: false,
            data: {
              country
            },
            success: function (res) {
              let html = '<option value=""></option>';

              if (res.success && res.data) {
                cities[country] = res.data;

                res.data.forEach((item, i) => {
                  html += `<option value="${item.city_id}">${item.region ? `${item.region}, ` : ''}${item.city}</option>`;
                });
              }

              $regionSelect.html(html);

              ajaxLoad = false;
            }
          });
        }
      });

    $calcForm
      .on('submit', () => {
        if (ajaxLoad) {
          return false;
        }

        const data = $calcForm.serializeArray();

        ///////////////// небольшая валидация формы калькулятора.
        let hasError = false;

        data.forEach(item => {
          if ((item.name === 'country' && item.value === '') || (item.name === 'region' && item.value === '') || (item.name === 'weight' && +item.value <= 0)) {
            hasError = true;
          }
        });

        ['country', 'region', 'weight'].forEach(key => {
          let found = false;

          data.forEach(item => {
            if (item.name === key) {
              found = true;
            }
          });

          if (!found) {
            hasError = true;
          }
        });

        if (hasError) {
          return false;
        }
        /////////////////

        ajaxLoad = true;

        $.ajax({
          url: '/cities/ajax-calc',
          dataType: 'html',
          type: 'post',
          cache: false,
          data,
          success: function (res) {
            $calcFormWrapper.hide();
            $('.calc-results', $calc).replaceWith(res);

            ajaxLoad = false;
          }
        });

        return false;
      });

    $calc
      .on('click', '.js-recalculate', () => {

        $('.calc-results', $calc).hide();
        $calcFormWrapper.show();

        return false;
      });
  }
});

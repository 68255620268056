/**
 * Created by Ilya Kvant [fatalerror2069@gmail.com] on 20.07.2018
 *
 * Слайдеры в калькуляторе доставки.
 */
$(function() {
  'use strict';

  function initCalcSliders() {
    let handlerHalfSize = 8;

    $('.js-range-slider')
      .each((i, sliderWrapper) => {
        /**
         * Обновление положения бегунка по позиции события.
         *
         * @param {Number} pointerX
         *
         * @return {void}
         */
        function updateHandlerPosition(pointerX) {
          let handlerPosition = parseInt(Math.max(handlerHalfSize, Math.min(sliderWidth - handlerHalfSize, pointerX - minX)));

          // Т.к. по дизайну точки со значениями стоят неравномерно,
          // нельзя провети точное процентное соотношение положения ползунка и значение.
          // Поэтому нужно искать ближайшую позицию среди фактически расставленных точек по пикселям.
          for (let k = 1; k < stepsCount; k++) {

            if (handlerPosition < valuePositions[k]) {
              let itemIndex  = -1,
                  inputValue = -1;

              // выбираем ближайшую к курсору - точку.
              if (handlerPosition - valuePositions[k - 1] < valuePositions[k] - handlerPosition) {
                // left
                itemIndex = k - 1;
              } else {
                // right
                itemIndex = k;
              }

              inputValue = (itemIndex + 1) * valuesStep;

              updateHandlerPositionByValue(inputValue);

              break;
            }
          }
        }

        /**
         * Обновление положения бегунка по значению.
         *
         * @param {Number} value
         *
         * @return {void}
         */
        function updateHandlerPositionByValue(value) {
          let valueIndex = Math.floor(+value / valuesStep);

          if (valueIndex > 0 && valueIndex < stepsCount) {
            valueIndex -= 1;
          } else if (valueIndex >= stepsCount) {
            valueIndex = stepsCount - 1;
          } else {
            valueIndex = 0;
          }

          $selected.width(`${valuePositions[valueIndex]}px`);

          if ($targetInput) {
            // устанавливаем новое значение в соотв. поле формы

            $targetInput.val(value);
          }
        }

        let $form        = $(sliderWrapper).parents('form'),
            $line        = $('.js-range-slider__line', sliderWrapper),
            $selected    = $('.js-range-slider__line-select', sliderWrapper),
            $handler     = $('> *', $selected),
            $values      = $('.js-range-slider__values', sliderWrapper),
            $targetInput = $form.find(`input[name="${$(sliderWrapper).data('target')}"]`),
            minX         = $line.offset().left,
            sliderWidth  = $line.width(),
            sliderActive = false;

        ////////////////////////////////////////////////////////////////////////////////////
        //////////////////////// генерация блока значений слайдера /////////////////////////

        let valuesStep = +$values.data('step'),
            valuesMin  = +$values.data('min'),
            valuesMax  = +$values.data('max'),
            stepsCount = valuesMax / valuesStep,
            valuesHtml = '';

        for (let k = valuesMin; k <= valuesMax; k += valuesStep) {
          valuesHtml += `<i>${k}</i>`;
        }
        $values.append(valuesHtml);

        // отступ для каждого элемента со значением, за исключением первого.
        let itemMarginLeft = (sliderWidth - $values.width()) / (stepsCount - 1);

        // позиции каждого из элементов со значением.
        let valuePositions = [];

        // выравниваем каждое значение так, чтобы крайние совпадали с границами слайдера
        $('i', $values).each((i, el) => {
          let $el = $(el);

          if (i > 0) {
            $el.css({marginLeft: itemMarginLeft});

            if (i === stepsCount - 1) {
              // позициии граничных значений отличаются от позиций промежуточных,
              // поэтому требуют отдельной обработки.
              valuePositions.push(sliderWidth - handlerHalfSize);
            } else {
              valuePositions.push($el.offset().left - minX + $el.width() / 2);
            }
          } else {
            valuePositions.push(handlerHalfSize);
          }
        });

        $values.width('100%');

        ////////////////////////////////////////////////////////////////////////////////////

        $(document)
          .on('mouseup touchend touchcancel', () => {
            sliderActive = false;
          })
          .on('mousemove touchmove', e => {
            if (sliderActive) {
              updateHandlerPosition(e.touches ? e.touches[0].pageX : e.pageX);
            }
          });

        $handler
          .on('mousedown touchstart', e => {
            e.preventDefault();
            e.stopPropagation();

            sliderActive = true;
          });

        // Первоначальная инициализация положения бегунка в зависимости от дефолтных значений в полях.
        updateHandlerPositionByValue($targetInput ? $targetInput.val() : 0);

        ////////////////////////////////////////////////////////////////////////////////////

        if ($targetInput.attr('name') === 'weight') {
          // стороннее событие обновления веса в калькуляторе(например через пресеты).

          $form.on('updateWeight', (e, data) => updateHandlerPositionByValue(data));
        }
      });

    // скрытие/показ ползунков "Объемного веса"
    $('[name="volume_weight"]')
      .on('change', (e) => {
        $('.js-volume-fields').toggle(e.currentTarget.checked);
      })
      .trigger('change');
  }

  if ($('.js-range-slider').length) {
    // небольшая задержка для подгрузки шрифтов и т.п.

    setTimeout(initCalcSliders, 100);
  }
});

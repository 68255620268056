/**
 * Created by Ilya Kvant [fatalerror2069@gmail.com] on 10.08.2018
 *
 * Скрипты страницы продукта.
 */
$(function () {

  if ($('.layout-shop-product').length) {

    // флаг того, что видим мобильную верстку.
    let isMobileWidth = $(window).width() < 800;

    const $mainPhoto = $('.js-product-main-photo');
    const $photos = $('.js-product-photos');

    const $tabs = $('.js-product-tabs');
    const $tabsContent = $('.js-product-tabs-content');

    $photos
      .on('click', 'i', (e) => {
        // переключение фото товара.

        const $t = $(e.target);

        $('.active', $photos).removeClass('active');
        $t.addClass('active');

        $mainPhoto.css({backgroundImage: $t.css('background-image')});

        return false;
      });

    $tabs
      .on('click', 'a', (e) => {
        // переключение табов доп. описания.

        $('.content', $tabs).remove();

        const $t = $(e.target);
        const $contentBlock = $('.content', $tabsContent).eq($t.index());

        $('.active', $tabs).removeClass('active');
        $t.addClass('active');

        $('.content', $tabsContent).hide();
        $contentBlock.show();

        if (isMobileWidth) {
          // на мобильной верстке приходится физически клонировать блок с текстом после таба.

          $t.after($contentBlock.clone());
        }

        return false;
      });

    $(window)
      .on('resize', () => {
        isMobileWidth = $(window).width() < 800;

        $('a', $tabs).first().trigger('click');
      })
      .trigger('resize');

    /////////////////

    let loadInProcess = false;

    $('.js-add2cart')
      .on('click', (e) => {
        // добавление товара в корзину

        if (loadInProcess) {
          return false;
        }
        loadInProcess = true;

        const id = +$(e.target).data('id');

        $.ajax({
          url: '/cart/ajax-add',
          dataType: 'json',
          type: 'post',
          cache: false,
          data: {
            id
          },
          success: function (res) {
            loadInProcess = false;

            if (res.success) {
              // обновляем кол-во товаров на иконке карзины.

              $('.js-cart-items-count')
                .show()
                .text(res.data);
            }
          }
        });

        return false;
      });
  }
});

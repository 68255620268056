/**
 * Created by Ilya Kvant [fatalerror2069@gmail.com] on 16.08.2018
 *
 * Форма подписки в подвале.
 */
$(function () {

  const $form = $('.js-subscribe');

  $form
    .on('beforeSubmit', () => {
      return false;
    })
    .on('ajaxComplete', (event, jqXHR, textStatus) => {
      if (+jqXHR.status === 200 && jqXHR.responseJSON.length === 0) {
        // вся валидация прошла успешно и форма сохранена

        $form.animate({opacity: 0}, 200, () => {
          $form
            .hide()
            .next()
            .addClass('showed');
        });
      }

      return false;
    });
});

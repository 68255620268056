/**
 * Created by Ilya Kvant [fatalerror2069@gmail.com] on 20.07.2018
 *
 * Работа со скролом тела таблицы "Стоимость доставки".
 */
$(function () {
  'use strict';

  let $tableWrapper = $('.js-prices-table');

  $tableWrapper
    .on('fix-table-inited', () => {

      let $table = $('.js-prices-table-main', $tableWrapper),
        $scrollWrapper = $('.js-table-scroll', $tableWrapper),
        $selected = $('.js-table-scroll__line-select', $scrollWrapper),
        $handler = $('> *', $selected),
        mainTableWidth = 0,
        scrollActive = false,
        handlerHalfSize = 8,
        firstColumnWidth = 0;

      let scrollMaxX, scrollMinX, tableMaxScroll;
      let touchStart = false,
        lastTouchPosition,
        touchStartPosition;

      /**
       * Перемещение таблицы и ползунка,
       * по координатам ползунка или на конкретное смещение(для скрола/свайпа).
       *
       * @param {Number} pageX - координата события.
       * @param {Number} shiftHandlerPosition - велечина смещения.
       *
       * @return {void}
       */
      function updateHandlerPosition(pageX, shiftHandlerPosition) {
        let handlerPosition = +$selected.width();

        if (shiftHandlerPosition !== undefined) {
          handlerPosition = Math.max(handlerHalfSize, Math.min(scrollMaxX, handlerPosition + shiftHandlerPosition));
        } else {
          handlerPosition = Math.max(handlerHalfSize, Math.min(scrollMaxX, pageX - scrollMinX));
        }

        let movePercents = (handlerPosition === handlerHalfSize ? 0 : handlerPosition) * 100 / (scrollMaxX);

        $selected.width(`${handlerPosition}px`);
        $table.css({left: -movePercents * tableMaxScroll / 100});
      }

      /**
       * Пересчет велечин контейнеров при изменении окна браузера.
       */
      function resetScroll() {
        mainTableWidth = $table.width();
        firstColumnWidth = $('thead td', $table).first().outerWidth();

        $scrollWrapper.css({marginLeft: firstColumnWidth + 2});

        scrollMaxX = $scrollWrapper.width() - handlerHalfSize;
        scrollMinX = $scrollWrapper.offset().left;
        tableMaxScroll = (mainTableWidth - 2) - $tableWrapper.outerWidth() - (window.innerWidth > 768 ? 0 : 20); // -2 это толщина обводки у последней ячейки.

        $selected.width(handlerHalfSize);
        $table.css({left: 0});
      }

      resetScroll();

      $table.parent()
        .on('mousewheel DOMMouseScroll', (e) => {
          // промотка таблицы спомощью стандартного скрола мышки или тачпада.
          // @todo test IE

          let originalEvent = e.originalEvent,
            delta = 0,
            isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

          if (isFirefox) {
            if (originalEvent.axis === originalEvent.HORIZONTAL_AXIS || (originalEvent.axis === originalEvent.VERTICAL_AXIS && originalEvent.shiftKey)) {
              delta = 60 * (originalEvent.detail > 0 ? 1 : -1);
            }
          } else {
            if (originalEvent.shiftKey) {
              delta = originalEvent.deltaY;
            } else {
              delta = originalEvent.wheelDeltaX * -1;
            }
          }

          if (Math.abs(delta) > 10) {
            // Небольшая погрешность, чтобы уменьшить количество ложных прокруток при прокрутке страницы с тачпада.

            updateHandlerPosition(null, delta);
          }
        })
        .on('touchstart', (e) => {
          // начало свайпа таблицы
          e.preventDefault();

          touchStart = true;

          lastTouchPosition = touchStartPosition = e.touches[0].pageX;
        })
        .on('touchmove', (e) => {
          // свайп непосредственно таблицы

          if (touchStart) {
            updateHandlerPosition(null, lastTouchPosition - e.touches[0].pageX);
            lastTouchPosition = e.touches[0].pageX;
          }
        });

      $(document)
        .on('mouseup touchend touchcancel', () => {
          touchStart = scrollActive = false;
        })
        .on('mousemove touchmove', e => {
          if (scrollActive) {
            let pageX = e.touches ? e.touches[0].pageX : e.pageX;

            updateHandlerPosition(pageX);
          }
        });

      $handler
        .on('mousedown touchstart', e => {
          e.preventDefault();
          e.stopPropagation();

          scrollActive = true;
        });

      let resizeTimeout = 0;
      $(window)
        .on('resize', () => {
          clearTimeout(resizeTimeout);

          resizeTimeout = setTimeout(resetScroll, 300);
        });
    });
});

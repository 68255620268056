/**
 * Created by Ilya Kvant [fatalerror2069@gmail.com] on 31.07.2018
 *
 * Работа фильтра товаров магазина.
 */
$(function () {

  const $filters = $('.js-catalog-filters');
  const $tags = $('.js-catalog-tags');

  if ($filters.length) {

    // кастомные выпадающие списки.
    $('.js-select2', $filters).select2({
      minimumResultsForSearch: Infinity
    });

    // при изменении фильтра отправляем форму.
    $('input, select', $filters)
      .on('change', () => {
        $filters.trigger('submit');
      });

    $tags
      .on('click', 'a', (e) => {
        // включение/отключение тегов.

        const $t = $(e.target);
        const $cloneFilters = $filters.first().clone();

        $('[name="tags"]', $cloneFilters).remove();

        const query = $cloneFilters.serialize();

        $t.toggleClass('active');

        let tags = [];

        $('.active', $tags).each((i, el) => {
          tags.push($(el).data('id'));
        });

        window.location.href = `${window.location.pathname}?${query}&tags=${tags.join(',')}`;

        return false;
      });
  }
});

/**
 * Created by Ilya Kvant [fatalerror2069@gmail.com] on 14.08.2018
 *
 * Подгрузка отзывов через ajax.
 */
$(function () {

  let page = null;
  let loadInProcess = false;

  // колонка с которой нужно начать добавление новых отзывов.
  let lastColumn = 0;
  let lastLength = 0;

  const $list = $('.js-reviews-items-list');

  $('.js-col', $list).each((i, el) => {
    let _len = $('> *', el).length;

    if (_len < lastLength) {
      lastColumn = i;
    }

    lastLength = _len;
  });

  $('.js-load-more-reviews')
    .on('click', (e) => {
      // подгрузка отзывов.

      if (loadInProcess) {
        return false;
      }
      loadInProcess = true;

      const $t = $(e.target);
      const link = $t.data('link');
      const lastPage = +$t.data('max');

      if (page === null) {
        page = +$t.data('page');
      }

      $.ajax({
        url: `${link}`,
        dataType: 'json',
        type: 'get',
        cache: false,
        data: {
          page
        },
        success: function (res) {

          if (res.data) {
            // распределение отзывов по колонкам.
            res.data.forEach(item => {
              $('.js-col', $list).eq(lastColumn).append(item);

              lastColumn += 1;

              if (lastColumn === 3) {
                lastColumn = 0;
              }
            });
          }

          loadInProcess = false;
        }
      });

      page += 1;

      if (page >= lastPage) {
        $t.hide();
      }

      return false;
    });
});

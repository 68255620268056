/**
 * Created by Ilya Kvant [fatalerror2069@gmail.com] on 23.08.2018
 *
 * Страница акций.
 */
$(function () {

  const $tags = $('.js-actions-categories');

  if ($tags.length) {

    // Фильтр акций по категориям.
    $tags
      .on('click', 'a', (e) => {
        // включение/отключение тегов.

        $(e.target).toggleClass('active');

        let tags = [];

        $('.active', $tags).each((i, el) => {
          tags.push($(el).data('id'));
        });

        window.location.href = `${window.location.pathname}?cats=${tags.join(',')}`;

        return false;
      });

    // скрытие/показ списка категорий.
    $('.js-toggle-cats')
      .on('click', (e) => {
        const $t = $(e.currentTarget);
        const textReverse = $t.data('text');

        if ($t.hasClass('reverse')) {
          $('a', $tags).each((k, el) => {
            if (k > 15) {
              $(el).hide();
            }
          });
        } else {
          $('a', $tags).show();
        }

        $t
          .toggleClass('reverse')
          .data('text', $('span', $t).text());

        $('span', $t).text(textReverse);

        return false;
      });
  }

  //////////////////////////////////////////
  // подгрузка акций через ajax.

  let page = null;
  let loadInProcess = false;

  const $list = $('.js-item-list');
  const $loadMore = $('.js-load-more-actions');

  if ($loadMore.length) {
    $loadMore
      .on('click', (e) => {
        if (loadInProcess) {
          return false;
        }
        loadInProcess = true;

        const $t = $(e.target);
        const url = $t.data('link');
        const lastPage = +$t.data('max');

        if (page === null) {
          page = +$t.data('page');
        }

        let cats = [];

        $('.active', $tags).each((i, el) => {
          cats.push($(el).data('id'));
        });

        cats = cats.join(',');

        $.ajax({
          url,
          dataType: 'html',
          type: 'get',
          cache: false,
          data: {
            page,
            cats
          },
          success: function (res) {
            $list.append(res);

            loadInProcess = false;
          }
        });

        page += 1;

        if (page >= lastPage) {
          $t.hide();
        }

        return false;
      });
  }
});

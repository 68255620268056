/**
 * Created by Ilya Kvant [fatalerror2069@gmail.com] on 20.07.2018
 *
 * Обработка пресетов веса для Калькулятора доставки.
 */
$(function() {
  'use strict';

  $('.js-calc-presets')
    .on('click', 'a', (e) => {
      let $this  = $(e.currentTarget),
          $p = $this.parent(),
          weight = +$this.data('value');

      $('.active', $p).removeClass('active');
      $this.addClass('active');
      $this.parents('form').trigger('updateWeight', weight);

      return false;
    });
});